<template>
  <div>
    <APAData v-if="showDataCenter" />
    <div class="flex">
      <div>
        <div v-if="livestream" class="mb-6 px-2 md:px-4 lg:px-6">
          <div
            class="relative w-full"
            style="padding-bottom: 56.25%; padding-top: 25px; height: 0"
          >
            <iframe
              :src="livestream"
              width="560"
              height="315"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="true"
              class="pin-t pin-l absolute top-0 left-0 h-full w-full"
            />
          </div>
        </div>
        <BasePageIndexElastic />
      </div>
      <Sidebar />
    </div>
  </div>
</template>

<script>
import BasePageIndexElastic from '../../../base/components/homepage/PageIndexElastic.vue'
import APAData from '~components/content/widgets/apa-data/APAData.vue'

export default {
  components: {
    BasePageIndexElastic,
    APAData,
  },
  computed: {
    frontpageSettings() {
      return this.$store.getters['frontpage/settings']
    },
    showDataCenter() {
      return this.frontpageSettings.showDataCenter
    },
    livestream() {
      return this.frontpageSettings.livestream
    },
  },
}
</script>
