<template>
  <PagedIndex />
</template>

<script>
import PagedIndex from './page/_page'
export default {
  components: {
    PagedIndex,
  },
}
</script>
