






































import { Component, Prop, Vue } from 'vue-property-decorator'

interface APADataView {
  tab: string
  label: string
  url: string
}
@Component
export default class APAData extends Vue {
  @Prop({ type: String, default: 'Landtagswahl 2019' }) readonly title!: string
  @Prop({
    type: Array,
    default: () => [
      {
        tab: 'vorarlberg',
        label: 'Vorarlberg',
        url: 'https://visualhosting.apa.at/3oiusd09/election/index.result.html?apaview=election:80000',
      },
      {
        tab: 'seats',
        label: 'Mandate',
        url: 'https://visualhosting.apa.at/3oiusd09/election/index.seat.html?apaview=election:80000',
      },
      {
        tab: 'details',
        label: 'Gemeinden',
        url: 'https://visualhosting.apa.at/3oiusd09/election/index.map.city.html?apaview=election:80000',
      },
      {
        tab: 'shift',
        label: 'Wählerstrom',
        url: 'https://visualhosting.apa.at/3oiusd09/election/index.shiftanalysis.html?apaview=election:80000',
      },
    ],
  })
  readonly views!: APADataView[]

  private tab: string = ''

  get selectedTabData(): APADataView {
    return this.views[this.views.findIndex((i) => i.tab === this.selectedTab)]
  }

  get selectedTab() {
    return this.tab || this.views[0].tab
  }
}
