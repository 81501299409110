


















import { Component } from 'vue-property-decorator'
import { generateOpenGraphMeta } from '~plugins/utils'
import Posts from '~components/content/Posts.vue'
import SearchPagination from '~components/content/widgets/pagination/SearchPagination.vue'
import { ListingPage, ListingPageVariables } from '~base/mixins/listings'
import { VueMetaInfo, VueMetaProperty } from '~base/common/types'

@Component<ListingPage>({
  components: {
    Posts,
    SearchPagination,
  },

  head(): VueMetaInfo {
    return {
      title: this.$config.siteName,
      meta: this.meta,
      link: this.link,
    }
  },

  apollo: {
    search: {
      query: require('~base/graphql/queries/FetchPostsElastic.gql'),
      prefetch({ route }: any): ListingPageVariables {
        return {
          first: this.perPage,
          page: parseInt(route.params.page || '0'),
        }
      },
      variables(): ListingPageVariables {
        return this.variables
      },
      result({ data }) {
        this.gotResult(data)
      },
    },
  },
})
export default class PageIndexElastic extends ListingPage {
  get showDataCenter() {
    return this.$store.getters['frontpage/settings'].showDataCenter
  }

  get linkPrefix(): string {
    return ``
  }

  get meta(): VueMetaProperty[] {
    return [...super.meta, ...generateOpenGraphMeta(this.$config)]
  }

  beforeMount() {
    this.$store.dispatch('news/pageChanged')
  }

  mounted() {
    this.$store.dispatch('ads/pageChanged', { pageType: `home` })
    this.$store.dispatch('tracking/trackHomepage')
  }

  loadMoreData() {
    const { endCursor } = this.search.pageInfo
    if (endCursor) {
      this.after = endCursor
      this.nextPage()
    }
  }
}
