<template>
  <PageIndexElastic />
</template>

<script>
import PageIndexElastic from '~components/homepage/PageIndexElastic.vue'

export default {
  components: {
    PageIndexElastic,
  },
}
</script>
